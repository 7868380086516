import React from "react";
import styled from "@emotion/styled";
import tw from "tailwind.macro";

const SVG = styled.svg`
  .store {
    ${tw`fill-current`};
    color: #f8b717;
  }

  .crunchyroll {
    ${tw`fill-current`};
    color: #f47522;
  }
`;
export default ({
    width = "100%",
    className = "",
    viewBox = "0 0 251.9 342.44",
}) => (
    <SVG
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={width}
        viewBox={viewBox}
        className={className}
    >
        <path
            className="crunchyroll"
            d="M396.14 126.84a107.18 107.18 0 0 0 0 214.36q3.67 0 7.29-.25a94.2 94.2 0 1 1 99.7-100.62q.19-3.14.19-6.31a107.18 107.18 0 0 0-107.18-107.18zm65.18 125.39a34.29 34.29 0 0 1-11.64-66.54A75.33 75.33 0 1 0 490 252.38a76.61 76.61 0 0 0-1.26-13.81 34.23 34.23 0 0 1-27.42 13.66zM290.4 393.33a17.2 17.2 0 0 1-6.61 1.34c-4.49 0-7.27-1.4-7.27-8.23s2.78-8.21 7.27-8.21a17.19 17.19 0 0 1 6.61 1.28v-5.34a20.68 20.68 0 0 0-7.68-1.5c-8.12 0-12.6 4.91-12.6 13.77s4.48 13.72 12.6 13.72a20.88 20.88 0 0 0 7.68-1.49zm12.6-13.55c0-1.57 3.88-1.53 7.45-1.58v-5.53s-13.83-.56-13.83 3.42v23.53H303zm35.75 7.89v-14.56h-6.28v15.42c0 4.26-1.56 5.72-5.45 5.72s-5.4-1.46-5.4-5.72v-15.42h-6.28v14.56c0 7.85 2.55 12.48 11.68 12.48s11.77-4.63 11.77-12.48zm33-1.21c0 8.87 4.49 13.73 12.61 13.73a20.7 20.7 0 0 0 7.69-1.5v-5.34a17.25 17.25 0 0 1-6.62 1.34c-4.49 0-7.27-1.39-7.27-8.23s2.78-8.23 7.27-8.23a17.43 17.43 0 0 1 6.62 1.28v-5.34a20.7 20.7 0 0 0-7.69-1.5c-8.04 0-12.53 4.91-12.53 13.79zm43.25-3.31v16.47h6.32v-15.94c0-6.92-4.35-11-11.74-11a14.4 14.4 0 0 0-5.32.91V363h-6.37v36.62h6.37V379.1a10.59 10.59 0 0 1 4.26-.8c5.94 0 6.48 2.5 6.48 4.85zm22.64 8.44l-5.95-18.48h-6.76l9.53 25.13c-.35.94 0 0-4.85 11.86h6.67l3.86-9.85 10.1-27.15h-6.75zM461 379.76c0-1.55 3.92-1.51 7.49-1.56v-5.53s-13.87-.57-13.87 3.42v23.53H461zm22.74-7c-8.1 0-12.59 4.85-12.59 13.71s4.49 13.71 12.59 13.71 12.6-4.85 12.6-13.71-4.48-13.74-12.59-13.74zm0 22.14c-3.79 0-6.19-1.38-6.19-8.43s2.45-8.47 6.2-8.47 6.19 1.38 6.19 8.43-2.4 8.44-6.19 8.44zm18.07 4.75h6.38V363h-6.38zm13.82 0H522V363h-6.38zm-164.7-19.96c0-1.13 3.22-1.36 5-1.36 5.67 0 5.56 2.46 5.56 4.8v16.48h6.2V383c0-7.38-3.69-10.33-10.92-10.33-6.6 0-12.07.59-12.07 5v21.94h6.25z"
            transform="translate(-270.12 -126.84)"
        />
        <path
            className="store"
            d="M298.3 461.9v-.76l4.86-5.61h.69c2.59 3 7.32 5.42 11.36 5.42s6.56-1.64 6.56-4c0-3.47-4-3.91-9-5.49-5.62-1.76-12.5-4.92-12.5-12.56 0-7.13 6.31-12.11 15.47-12.11 6.68 0 12.43 2.65 15.4 6.18v.69l-4.86 5.56h-.7a13.89 13.89 0 0 0-9.72-4.48c-3.28 0-5.55 1.39-5.55 3.66 0 2.71 3.09 3.78 6.44 4.92 7 2.21 15.14 4.36 15.14 13.57 0 8-7.26 12.43-16.09 12.43-7.34-.04-13.8-2.94-17.5-7.42zM358.89 468.53H349v-32.95h-12.89v-8.08h35.66v8.08h-12.88zM374 448c0-12.18 9.72-21.26 22.53-21.26S419 435.83 419 448s-9.72 21.27-22.47 21.27S374 460.2 374 448zm35 0a12.43 12.43 0 1 0-12.43 12.37A12.1 12.1 0 0 0 409 448zM460 467.77l-.44.76h-9.78l-8.27-15.4h-5.75v15.4h-9.84v-41h17.67c10.35 0 15.84 5 15.84 13.07 0 5.55-3.15 9.4-8.08 11.29zm-24.24-21.4h7.89c3.35 0 5.75-2 5.75-5.3s-2.4-5.49-5.75-5.49h-7.89zM476.8 435.58v8.52h15.53v7.7H476.8v8.65H497v8.08h-30v-41h29.92v8.08z"
            transform="translate(-270.12 -126.84)"
        />
    </SVG>
);
