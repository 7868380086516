import styled from "@emotion/styled";
import { css } from "@emotion/core";
import tw from "tailwind.macro";

import media from "@styles/media";

const S = {};

S.Offcanvas = styled.div`
  ${tw`fixed left-0 w-full bg-woodsmoke text-white overflow-y-auto opacity-0 invisible`};
  top: 3.75rem;
  height: calc(100% - 3.75rem);
  z-index: 1050;
  transform: translateX(-1.5625rem);
  transition: transform 0.1s, opacity 0.1s cubic-bezier(0, 0, 0.25, 1),
    visibility 0s linear 0.1s;

  ${media.menuSm`
    width: 18.75rem;
  `};

  ::-webkit-scrollbar {
    width: 0.75rem;
  }

  ::-webkit-scrollbar-thumb {
    ${tw`bg-scarpa-flow`};
    border: 0.1875rem solid ${tw`text-shark`.color};

    &:active,
    &:hover {
      ${tw`bg-silver-chalice`};
      border: 0.125rem solid ${tw`text-shark`.color};
    }
  }

  ::-webkit-scrollbar-track {
    ${tw`bg-shark`};
  }

  a {
    transition: none;
  }

  .section {
    padding: 0.625rem 0;

    &:first-of-type {
      border-bottom: 0.125rem solid ${tw`text-shark`.color};
    }
  }

  .title {
    ${tw`text-xs uppercase text-silver-chalice font-semibold block`};
    line-height: 1.33;
    padding: 0.625rem 1.125rem;
  }

  .menu {
    ${tw`list-none pl-0`};
  }

  .menu-item {
    ${tw`cursor-pointer select-none`};
  }

  .menu-item-title {
    ${tw`block text-alabaster hover:bg-shark`};
    padding: 0.75rem 1.125rem;
    line-height: 1.5;
  }

  ${({ menuIsOpen }) => menuIsOpen
    && css`
      ${tw`opacity-100 visible`};
      transform: translateX(0);
      transition: transform 0.15s, opacity 0.15s cubic-bezier(0, 0, 0.25, 1),
        visibility 0s;
    `};
`;

export default S;
