import axios from "axios";

// eslint-disable-next-line import/prefer-default-export
export async function submitCode(code) {
    const url = "https://bd722fhwyk.execute-api.us-east-1.amazonaws.com/Development/redeem";
    const res = await axios.post(url, { code }, {
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        },
    });

    if (res.data.errorMessage || res.data.code === "401") {
        return { statusCode: 401 };
    }
    return { statusCode: 200, code: res.data.cr_code };
}
