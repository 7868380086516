import React from "react";
import styled from "@emotion/styled";
import tw from "tailwind.macro";

const SVG = styled.svg`
  path {
    ${tw`stroke-current text-white`};
  }
`;
export default ({
    width = "100%",
    className = "",
    viewBox = "0 0 28.75 48.76",
}) => (
    <SVG
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={width}
        viewBox={viewBox}
        className={className}
    >
        <path
            fill="none"
            strokeMiterlimit="10"
            strokeWidth="3"
            d="M27.69 1.06L2.12 26.63 23.2 47.7"
        />
    </SVG>
);
