import { withPrefix } from "gatsby";

export const fontface = `
  @font-face {
    font-family: 'Lato';
    src: url(${withPrefix("/fonts/Lato-MediumItalic.woff2")}) format('woff2'),
      url(${withPrefix("/fonts/Lato-MediumItalic.woff")}) format('woff');
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: 'Lato';
    src: url(${withPrefix("/fonts/Lato-Medium.woff2")}) format('woff2'),
      url(${withPrefix("/fonts/Lato-Medium.woff")}) format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Lato';
    src: url(${withPrefix("/fonts/Lato-Semibold.woff2")}) format('woff2'),
      url(${withPrefix("/fonts/Lato-Semibold.woff")}) format('woff');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Lato';
    src: url(${withPrefix("/fonts/Lato-Heavy.woff2")}) format('woff2'),
      url(${withPrefix("/fonts/Lato-Heavy.woff")}) format('woff');
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: 'Lato';
    src: url(${withPrefix("/fonts/Lato-Regular.woff2")}) format('woff2'),
      url(${withPrefix("/fonts/Lato-Regular.woff")}) format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Nutmeg';
    src: url(${withPrefix("/fonts/Nutmeg-Regular.woff2")}) format('woff2'),
      url(${withPrefix("/fonts/Nutmeg-Regular.woff")}) format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Nutmeg';
    src: url(${withPrefix("/fonts/Nutmeg-BoldItalic.woff2")}) format('woff2'),
      url(${withPrefix("/fonts/Nutmeg-BoldItalic.woff")}) format('woff');
    font-weight: 600;
    font-style: italic;
  }

  @font-face {
    font-family: 'Nutmeg';
    src: url(${withPrefix("/fonts/Nutmeg-Bold.woff2")}) format('woff2'),
      url(${withPrefix("/fonts/Nutmeg-Bold.woff")}) format('woff');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Nutmeg';
    src: url(${withPrefix("/fonts/Nutmeg-ExtraBold.woff2")}) format('woff2'),
      url(${withPrefix("/fonts/Nutmeg-ExtraBold.woff")}) format('woff');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'Nutmeg';
    src: url(${withPrefix("/fonts/Nutmeg-Black.woff2")}) format('woff2'),
      url(${withPrefix("/fonts/Nutmeg-Black.woff")}) format('woff');
    font-weight: 900;
    font-style: normal;
  }
`;

export const fontello = `
  @font-face {
    font-family: 'fontello';
    src: url(${withPrefix("/fonts/fontello.eot?16171620")});
    src: url(${withPrefix(
        "/fonts/fontello.eot?16171620#iefix",
    )}) format('embedded-opentype'),
      url(${withPrefix("/fonts/fontello.woff2?16171620")}) format('woff2'),
      url(${withPrefix("/fonts/fontello.woff?16171620")}) format('woff'),
      url(${withPrefix("/fonts/fontello.ttf?16171620")}) format('truetype'),
      url(${withPrefix("/fonts/fontello.svg?16171620#fontello")}) format('svg');
    font-weight: normal;
    font-style: normal;
  }

  [class^='icon-']:before,
  [class*=' icon-']:before {
    font-family: 'fontello';
    font-style: normal;
    font-weight: normal;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0.2em;
    text-align: center;

    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: 0.2em;
  }

  .icon-twitter:before {
    content: '\f099';
    transform: translateY(-1px);
  }
  .icon-facebook:before {
    content: '\f09a';
  }
  .icon-youtube-play:before {
    content: '\f16a';
    transform: translateY(-1px);
  }
  .icon-instagram:before {
    content: '\f16d';
    transform: translateY(-1px);
  }
  .icon-twitch:before {
    content: '\f1e8';
  }
`;
