import styled from "@emotion/styled";
import { css } from "@emotion/core";
import tw from "tailwind.macro";

import media from "@styles/media";

const S = {};

S.NavButton = styled.div`
  ${tw`cursor-pointer flex items-center hover:bg-woodsmoke relative`};
  padding: 1.125rem;
  transition: background-color 0.1s cubic-bezier(0, 0, 0.25, 1);

  &:hover {
    path,
    .menu-title {
      ${tw`text-white`};
    }
  }

  .menu-tab {
    ${tw`absolute inset-0`};
  }

  .menu-title {
    ${tw`uppercase font-black text-sm text-silver ml-3 hidden`};
    line-height: 1.29;

    ${media.menuSm`
      ${tw`block`};
    `};
  }

  ${({ menuIsOpen }) => menuIsOpen
    && css`
      ${tw`bg-woodsmoke`};

      path,
      .menu-title {
        ${tw`text-white`};
      }
    `};
`;

export default S;
