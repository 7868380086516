import React from "react";
import styled from "@emotion/styled";
import tw from "tailwind.macro";

const SVG = styled.svg`
  path {
    ${tw`text-white fill-current`};
  }
`;
export default ({
    width = "24",
    height = "24",
    className = "",
    viewBox = "0 0 24 24",
}) => (
    <SVG
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox={viewBox}
        className={className}
    >
        <path fillRule="evenodd" d="M8.6 7.4L10 6l6 6-6 6-1.4-1.4 4.6-4.6z" />
    </SVG>
);
