import React from "react";
import styled from "@emotion/styled";
import tw from "tailwind.macro";

const SVG = styled.svg`
  .fill {
    ${tw`fill-current text-white`};
    transition: color 0.2s ease-in-out;
  }

  &:hover {
    .fill {
      ${tw`text-teal`};
    }
  }
`;
export default ({
    width = "100%",
    className = "",
    viewBox = "0 0 240 240",
}) => (
    <SVG
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={width}
        viewBox={viewBox}
        className={className}
    >
        <circle className="fill" cx="120.01" cy="120" r="21.66" />
        <path
            className="fill"
            d="M170.68,81.69a22,22,0,0,0-12.35-12.36C149.8,66,129.47,66.72,120,66.72s-29.75-.78-38.31,2.61A22,22,0,0,0,69.35,81.69C66,90.22,66.74,110.55,66.74,120s-.78,29.76,2.61,38.31A22,22,0,0,0,81.7,170.67c8.53,3.36,28.86,2.61,38.31,2.61s29.76.78,38.32-2.61a22,22,0,0,0,12.35-12.36c3.39-8.53,2.61-28.86,2.61-38.31S174.07,90.24,170.68,81.69ZM120,153.32A33.32,33.32,0,1,1,153.34,120,33.27,33.27,0,0,1,120,153.32ZM154.7,93.09a7.78,7.78,0,1,1,7.77-7.78A7.75,7.75,0,0,1,154.7,93.09Z"
        />
        <path
            className="fill"
            d="M120,0A120,120,0,1,0,240,120,120,120,0,0,0,120,0Zm64.54,146.8c-.52,10.41-2.9,19.63-10.5,27.23s-16.82,10-27.23,10.5c-10.73.61-42.89.61-53.62,0C82.78,184,73.58,181.63,66,174s-10-16.82-10.5-27.23c-.61-10.73-.61-42.9,0-53.63C56,82.76,58.33,73.54,66,65.94S82.81,56,93.19,55.47c10.73-.61,42.89-.61,53.62,0C157.22,56,166.45,58.37,174,66s10,16.82,10.5,27.23C185.15,103.93,185.15,136.07,184.54,146.8Z"
        />
    </SVG>
);
