import React from "react";
import PremiumLogo from "../../assets/images/crunchy-premium-logo.svg";
import HimeImage from "../../assets/images/hime-cropped.png";
import RedeemForm from "../RedeemForm";

export default function RedeemSection() {
    return (
        <section className="redeem-section overflow-visible relative">
            <div className="content-container max-w-1366 relative h-full mx-auto">
                <div className="redeem-form absolute">
                    <img src={PremiumLogo} alt="Premium Logo" className="w-full md:w-10/12 lg:w-8/12" />
                    <p className="text-center md:text-left text-white opacity-50 my-4 xl:my-6 text-base md:text-lg xl:text-xl">Canjea tu código aquí y comienza a disfrutar del mejor anime.</p>
                    <RedeemForm />
                </div>
                <img src={HimeImage} alt="Hime portrait" className="absolute h-full object-contain hime-portrait" />
            </div>
        </section>
    );
}
