import React from "react";
import styled from "@emotion/styled";
import tw from "tailwind.macro";

const SVG = styled.svg`
  .fill {
    ${tw`fill-current text-white`};
    transition: color 0.2s ease-in-out;
  }

  &:hover {
    .fill {
      ${tw`text-teal`};
    }
  }
`;
export default ({
    width = "100%",
    className = "",
    viewBox = "0 0 240 240",
}) => (
    <SVG
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={width}
        viewBox={viewBox}
        className={className}
    >
        <path
            className="fill"
            d="M120,0A120,120,0,1,0,240,120,120,120,0,0,0,120,0Zm35.5,73.27H144.45c-10.9,0-14.29,6.76-14.29,13.69v16.45h24.31l-3.88,25.34H130.16V190h-27.4V128.75H80.5V103.41h22.26V84.09c0-22,13.09-34.11,33.12-34.11A134.16,134.16,0,0,1,155.5,51.7Z"
        />
    </SVG>
);
