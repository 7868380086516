import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { withPrefix } from "gatsby";

import SVG from "@components/svg";
import Link from "@components/link";
import TabNavigation from "@components/layout/nav/tab/TabNavigation";
import { useToggle } from "@hooks/use-toggle";
import S from "./MenuDropdown.styled";

const MenuDropdown = ({
    title, data, dataSrc, onLinkClick, ...rest
}) => {
    const { isOpen, toggle } = useToggle();

    useEffect(() => {
        const { pathname } = window.location;

        if (dataSrc === "graphql") {
            const matchPath = data.filter(
                ({ node: { url } }) => withPrefix(url) === pathname,
            );

            if (matchPath.length > 0) {
                toggle();
            }
        }
    }, []);

    return (
        <S.MenuItem className="menu-item" {...rest}>
            <TabNavigation onEnterPress={toggle}>
                <S.MenuItemTitle
                    isOpen={isOpen}
                    onClick={toggle}
                    className="menu-item-title"
                >
                    <span>{title}</span>
                    <span className="menu-icon">
                        <SVG icon="menu_chevron" className="menu-icon-chevron" />
                    </span>
                </S.MenuItemTitle>
            </TabNavigation>

            {isOpen && (
                <S.Submenu>
                    {dataSrc === "config"
                        ? (data || []).map(({ title, url }) => (
                            <li key={title}>
                                <Link
                                    to={url}
                                    className="submenu-item-title"
                                    onClick={onLinkClick}
                                >
                                    {title}
                                </Link>
                            </li>
                        ))
                        : data.map(({ node: { title, url } }) => (
                            <Fragment key={title.hub}>
                                {title.nav && (
                                    <li>
                                        <Link
                                            to={url}
                                            className="submenu-item-title"
                                            activeClassName="active"
                                            onClick={onLinkClick}
                                        >
                                            {title.nav}
                                        </Link>
                                    </li>
                                )}
                            </Fragment>
                        ))}
                </S.Submenu>
            )}
        </S.MenuItem>
    );
};

MenuDropdown.propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    dataSrc: PropTypes.string.isRequired,
    onLinkClick: PropTypes.func,
};

MenuDropdown.defaultProps = {
    onLinkClick: () => {},
};

export default MenuDropdown;
