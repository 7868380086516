import React from "react";
import PropTypes from "prop-types";

function Input({
    name,
    className = "",
    error,
    handleChange,
    value,
    placeholder = "",
    disabled = false,
}) {
    return (
        <>
            <input
                id={name}
                name={name}
                className={`
                    input w-full md:w-10/12 lg:w-8/12 rounded-none shadow-none
                    ${error ? "input-invalid" : ""}
                    ${className}
                `}
                onChange={handleChange}
                value={value}
                placeholder={placeholder}
                disabled={disabled}
            />
            {error && (
                <label
                    className="block w-full mt-2 -mb-4 text-xs text-center md:text-left text-states-error text-brand-red-500 md:w-10/12 lg:w-8/12 md:mb-0"
                    htmlFor={name}
                >
                    {error}
                </label>
            )}
        </>
    );
}

Input.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    error: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
};

Input.defaultProps = {
    className: "",
    error: null,
    placeholder: "",
    disabled: false,
};

export default Input;
