import React from "react";

import { MenuConsumer } from "@contexts/MenuContext";
import S from "./Overlay.styled";

const Overlay = () => (
    <MenuConsumer>
        {({ isOpen, toggle }) => {
            const onOverlayClick = () => {
                toggle();

                const bodyClass = document.body.classList;
                bodyClass.contains("menu-open") && bodyClass.remove("menu-open");
            };

            return <>{isOpen && <S.Overlay onClick={onOverlayClick} />}</>;
        }}
    </MenuConsumer>
);

export default Overlay;
