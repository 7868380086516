import styled from "@emotion/styled";
import tw from "tailwind.macro";

const S = {};

S.Overlay = styled.div`
  ${tw`fixed inset-0`};
  z-index: 1050;
  background-color: rgba(35, 37, 43, 0.5);
`;

export default S;
