import styled from "@emotion/styled";
import tw from "tailwind.macro";

import media from "@styles/media";

const S = {};

S.MenuLogo = styled.div`
  path {
    transition: color 0.15s cubic-bezier(0, 0, 0.25, 1);
  }

  &:hover {
    path {
      ${tw`text-white`};
    }
  }

  .hidden-mobile {
    ${tw`hidden`};
  }

  ${media.menuSm`
  .hidden-mobile {
    ${tw`block`};
  }

  .hidden-tablet {
    ${tw`hidden`};
  }
`};
`;

export default S;
