import ValidationError from "./ValidationError";
import errors from "../data/errors.json";

// eslint-disable-next-line import/prefer-default-export
export function validateRedeemCode(code) {
    const redeemFormatRegex = /^[a-z0-9]{19,20}$/i;
    if (code.length === 0) {
        throw new ValidationError(
            errors.emptyFieldError.message,
            errors.emptyFieldError.name,
        );
    } else if (redeemFormatRegex.test(code) === false) {
        throw new ValidationError(
            errors.formatError.message,
            errors.formatError.name,
        );
    }
}
