import React from "react";
import styled from "@emotion/styled";
import tw from "tailwind.macro";

const SVG = styled.svg`
  path {
    ${tw`stroke-current`};
    color: #2bbdbb;
  }
`;

export default ({
    width = "100%",
    className = "",
    viewBox = "0 0 66.52 74.34",
}) => (
    <SVG
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={width}
        viewBox={viewBox}
        className={className}
    >
        <defs>
            <clipPath id="clip-path" transform="translate(-72.58 -1462.74)">
                <path fill="none" d="M-39.52 1179.01h792v612h-792z" />
            </clipPath>
        </defs>

        <g clipPath="url(#clip-path)">
            <path
                strokeLinejoin="bevel"
                strokeWidth="2"
                fill="none"
                d="M20.09 74.03l13.17-40.77 13.16 40.77"
            />
            <path
                fill="none"
                strokeWidth="2"
                strokeMiterlimit="10"
                d="M94.83 1510.44a18.16 18.16 0 1 1 22 0M90.46 1524.36a32.26 32.26 0 1 1 30.86 0"
                transform="translate(-72.58 -1462.74)"
            />
            <path
                fill="none"
                strokeWidth="2"
                strokeMiterlimit="10"
                d="M33.26 28.84V22.1M29.73 31.4l-4.77-4.76M36.16 31.22l4.76-4.77M32.86 63.43l11.97 5.66M21.69 69.09l19.89-10.08"
            />
        </g>
    </SVG>
);
