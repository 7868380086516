import React from "react";
import styled from "@emotion/styled";
import tw from "tailwind.macro";

const SVG = styled.svg`
  path {
    ${tw`text-silver fill-current`};
  }
`;
export default ({
    width = "24",
    height = "24",
    className = "",
    viewBox = "0 0 24 24",
}) => (
    <SVG
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox={viewBox}
        className={className}
    >
        <path
            fillRule="evenodd"
            d="M21 4a1 1 0 0 1 0 2H3a1 1 0 0 1 0-2h18zm0 7a1 1 0 0 1 0 2H3a1 1 0 0 1 0-2h18zm0 7a1 1 0 0 1 0 2H3a1 1 0 0 1 0-2h18z"
        />
    </SVG>
);
