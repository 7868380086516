import React from "react";
import styled from "@emotion/styled";
import tw from "tailwind.macro";

const SVG = styled.svg`
  path {
    ${tw`stroke-current`};
    color: #2bbdbb;
  }
`;
export default ({
    width = "100%",
    className = "",
    viewBox = "0 0 124.08 123.87",
}) => (
    <SVG
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={width}
        viewBox={viewBox}
        className={className}
    >
        <path
            fill="none"
            strokeMiterlimit="10"
            strokeWidth="3"
            d="M1.5 122.37h121.08V1.5h-20.26v9.72H87.21V1.5H36.87v9.72H21.75V1.5H1.71L1.5 122.37z"
        />
        <path
            fill="none"
            strokeMiterlimit="10"
            strokeWidth="3"
            d="M21.66 26.26h33.9v33.9h-33.9zM68.33 26.26h33.9v33.9h-33.9zM21.66 73.09h33.9v33.9h-33.9zM68.33 73.09h33.9v33.9h-33.9z"
        />
    </SVG>
);
