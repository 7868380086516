import styled from "@emotion/styled";
import tw from "tailwind.macro";
import media from "@styles/media";

const S = {};

S.Header = styled.header`
  .header {
    ${tw`fixed top-0 left-0 w-full bg-shark`};
    box-sizing: border-box;
    z-index: 1060;

    a {
      ${tw`focus:outline-none active:shadow-none`};

      &:focus {
        box-shadow: 0 0 0 0.25rem rgba(160, 160, 160, 0.75) inset;
      }
    }
  }

  .header-placeholder {
    height: ${({ showBar }) => (showBar ? "6.875rem" : "3.75rem")};

    @media (min-width: 80rem) {
      height: ${({ showBar }) => (showBar ? "7.75rem" : "3.75rem")};
    }
  }

  .content {  
    ${tw`flex`};
    height: 3.75rem;

    > * {
      line-height: 0;
    }
  }

  .menu-left {
    ${tw`flex`};

    ${media.menuSm`
      ${tw`flex-1`};
    `};
  }

  .menu-right {
    ${tw`flex justify-end flex-1`};
  }

  .header-svg {
    padding: 1.125rem;
  }
`;

export default S;
