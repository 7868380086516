import React, { useEffect } from "react";

import "../styles/tailwind/fonts.css";
import "../styles/tailwind.build.css";
import "../styles/sections.css";
import "../styles/footer.css";

import SEO from "../components/seo";
import RedeemSection from "../components/sections/RedeemSection";
import BenefitsSection from "../components/sections/BenefitsSection";
import SeriesSection from "../components/sections/SeriesSection";
import Footer from "../components/sections/Footer";
import Layout from "../components/layout/layout";

const IndexPage = () => {
    return (
        <Layout>
            <SEO />
            <RedeemSection />
            <BenefitsSection />
            <SeriesSection />
            <Footer />
        </Layout>
    );
};

export default IndexPage;
