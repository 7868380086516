import React from "react";
import styled from "@emotion/styled";
import tw from "tailwind.macro";

const SVG = styled.svg`
  color: #2bbdbb;

  .dot {
    ${tw`fill-current`};
  }

  .stroke {
    ${tw`stroke-current`};
  }
`;

export default ({
    width = "100%",
    className = "",
    viewBox = "0 0 42.53 74.9",
}) => (
    <SVG
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={width}
        viewBox={viewBox}
        className={className}
    >
        <path
            className="dot"
            d="M733.85 702.34a2.53 2.53 0 0 1-2.48 2.63 2.61 2.61 0 0 1-2.7-2.58 2.58 2.58 0 0 1 2.64-2.62 2.51 2.51 0 0 1 2.54 2.57z"
            transform="translate(-709.86 -634.49)"
        />
        <path
            className="stroke"
            fill="none"
            strokeMiterlimit="10"
            strokeWidth="2"
            d="M41.53 54.87V73.9H1V1h40.53V54.87zM1.03 10.53h40.34M1.03 62.34h40.34"
        />
    </SVG>
);
