import styled from "@emotion/styled";
import tw from "tailwind.macro";

import media from "@styles/media";

const S = {};

S.UserActions = styled.ul`
  ${tw`list-none pl-0 flex`};

  li {
    ${tw`hover:bg-woodsmoke`};
    transition: background-color 0.1s cubic-bezier(0, 0, 0.25, 1);

    &:hover {
      path {
        ${tw`text-white`};
      }
    }
  }

  a {
    ${tw`inline-block`};
  }

  .item-queue {
    ${tw`hidden`};

    ${media.menuSm`
      ${tw`block`};
    `};
  }
`;

export default S;
