import React from "react";

import SVG from "@components/svg";
import Link from "@components/link";
import S from "./MenuLogo.styled";

const MenuLogo = () => (
    <S.MenuLogo>
        <Link to="https://www.crunchyroll.com/" className="hidden-tablet">
            <SVG icon="menu_cr_mobile" />
        </Link>
        <Link to="https://www.crunchyroll.com/" className="hidden-mobile">
            <SVG icon="menu_cr_logo" />
        </Link>
    </S.MenuLogo>
);

export default MenuLogo;
