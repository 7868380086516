import { css } from "@emotion/core";
import tw from "tailwind.macro";
import media from "@styles/media";
import { fontface, fontello } from "@styles/fontface";

const globalStyles = css`
  ${fontface};
  ${fontello};

  html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    font-size: 16px;
    overflow-anchor: none;
    line-height: 1.15;
  }

  *,
  :after,
  :before {
    box-sizing: inherit;
  }

  body {
    ${tw`font-body text-base m-0 antialiased font-body font-normal`};
  }

  main {
    ${tw`block`};
  }

  b,
  strong {
    font-weight: bolder;
  }

  code,
  samp {
    font-family: monospace, monospace;
    font-size: 1em;
  }

  small {
    font-size: 80%;
  }

  sub,
  sup {
    ${tw`relative align-baseline`};
    font-size: 75%;
    line-height: 0;
  }

  sub {
    bottom: -0.25em;
  }

  sup {
    top: -0.5em;
  }

  button {
    ${tw`overflow-visible`};
  }

  button,
  [type='button'],
  [type='reset'],
  [type='submit'] {
    -webkit-appearance: button;
  }

  button::-moz-focus-inner,
  [type='button']::-moz-focus-inner,
  [type='reset']::-moz-focus-inner,
  [type='submit']::-moz-focus-inner {
    ${tw`p-0`};
    border-style: none;
  }

  button:-moz-focusring,
  [type='button']:-moz-focusring,
  [type='reset']:-moz-focusring,
  [type='submit']:-moz-focusring {
    outline: 1px dotted ButtonText;
  }

  button:focus {
    ${tw`outline-none`};
  }

  legend {
    ${tw`p-0 table max-w-full whitespace-normal`};
    box-sizing: border-box;
    color: inherit;
  }

  [hidden] {
    ${tw`hidden`};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${tw`m-0 font-header font-semibold`};
  }

  h1,
  h2,
  h3 {
    ${tw`uppercase`};
  }

  h1 {
    ${tw`text-xl text-teal`};
    margin-bottom: 1.5625rem;
    letter-spacing: 0.01875rem;

    ${media.xs`
      ${tw`text-2xl`};
    `};

    ${media.md`
      font-size: 2.1875rem;
    `};

    ${media.lg`
      font-size: 2.8125rem;
    `};
  }

  h2 {
    ${tw`text-base`};
    letter-spacing: 0.01875rem;
  }

  img {
    ${tw`max-w-full`};
    border-style: none;
  }

  a {
    ${tw`no-underline bg-transparent`};
    transition: 0.2s ease-in-out;
    transition-property: color, background-color;
  }

  figure,
  p,
  ol,
  ul {
    ${tw`m-0`};
  }

  p {
    ${tw`leading-tight`};

    ${media.md`
      ${tw`text-xl`};
    `};

    ${media.lg`
      font-size: 1.5625rem;
    `};
  }

  .menu-open {
    ${tw`overflow-hidden`};
  }

  .visible-mobile {
    ${media.md`
      ${tw`hidden`};
    `};
  }

  .visible-mobile-tablet {
    ${media.lg`
      ${tw`hidden`};
    `};
  }

  .visible-tablet {
    ${tw`hidden`};

    ${media.md`
      ${tw`block`};
    `};

    ${media.lg`
      ${tw`hidden`};
    `};
  }

  .visible-tablet-desktop {
    ${tw`hidden`};

    ${media.md`
      ${tw`block`};
    `};
  }

  .visible-desktop {
    ${tw`hidden`};

    ${media.lg`
      ${tw`block`};
    `};
  }

  .modal-open {
    ${tw`overflow-hidden`};
  }
`;

export default globalStyles;
