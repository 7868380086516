import React from "react";
import { graphql, useStaticQuery } from "gatsby";

export default function Footer() {
    const footerLinks = useStaticQuery(
        graphql`
            query {
                allFooterLinksJson {
                    edges {
                        node {
                            label
                            link
                        }
                    }
                }
            }
        `
    );

    return (
        <footer className="px-2 pt-20 md:mx-64 cr-footer">
            <div className="flex justify-center">
                {footerLinks.allFooterLinksJson.edges.map(
                    ({ node: { label, link } }, i) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={i} className="flex items-center">
                            <a
                                href={link}
                                className="inline-block mx-2 footer-text md:mx-6"
                            >
                                {label}
                            </a>
                            {i !==
                                footerLinks.allFooterLinksJson.edges.length -
                                    1 && <div className="links-divider" />}
                        </div>
                    )
                )}
            </div>
            <div className="flex justify-center mt-3">
                <div className="flex items-center">
                    <button
                        onClick={event => {
                            event.preventDefault();
                            window.evidon?.notice?.showPreferencesDialog();
                        }}
                        className="inline-block mx-2 footer-text md:mx-6"
                        type="button"
                    >
                        Gestión de cookies
                    </button>
                    <div className="links-divider" />
                </div>
                <div className="flex items-center">
                    <a
                        href="https://www.sonypictures.com/corp/privacy.html#section11B"
                        className="inline-block mx-2 footer-text md:mx-6"
                    >
                        Ad Choices
                    </a>
                    <div className="links-divider" />
                </div>
                <div className="flex items-center">
                    <a
                        target="_blank"
                        href="https://privacyportal-cdn.onetrust.com/dsarwebform/d19e506f-1a64-463d-94e4-914dd635817d/b9eb997c-9ede-451b-8fd4-29891782a928.html"
                        className="inline-block mx-2 footer-text md:mx-6"
                    >
                        Do not sell my personal information
                    </a>
                </div>
            </div>
            <span className="block my-6 mb-12 font-bold footer-text">
                © 2006-2020 Ellation, LLC.
            </span>
        </footer>
    );
}
