import React from "react";

import GamesLogo from "./GamesLogo";
import GamesLogoVertical from "./GamesLogoVertical";
import CRLogo from "./CRLogo";
import CRStoreHorizontal from "./CRStoreHorizontal";
import CRStoreVertical from "./CRStoreVertical";
import News from "./News";
import Phone from "./Phone";
import Calendar from "./Calendar";
import CarouselArrow from "./CarouselArrow";
import SocialTwitter from "./SocialTwitter";
import SocialInstagram from "./SocialInstagram";
import SocialFacebook from "./SocialFacebook";
import MenuCRMark from "./MenuCRMark";
import MenuCRLogo from "./MenuCRLogo";
import MenuBurger from "./MenuBurger";
import MenuSearch from "./MenuSearch";
import MenuQueue from "./MenuQueue";
import MenuChevron from "./MenuChevron";
import MenuUserSettings from "./MenuUserSettings";

export default ({ icon, ...props }) => {
    switch (icon) {
    case "crgames_logo":
        return <GamesLogo {...props} />;
    case "crgames_logo_v":
        return <GamesLogoVertical {...props} />;
    case "crlogo":
        return <CRLogo {...props} />;
    case "crstore_h":
        return <CRStoreHorizontal {...props} />;
    case "crstore_v":
        return <CRStoreVertical {...props} />;
    case "news":
        return <News {...props} />;
    case "phone":
        return <Phone {...props} />;
    case "calendar":
        return <Calendar {...props} />;
    case "carousel_arrow":
        return <CarouselArrow {...props} />;
    case "social_twitter":
        return <SocialTwitter {...props} />;
    case "social_instagram":
        return <SocialInstagram {...props} />;
    case "social_facebook":
        return <SocialFacebook {...props} />;
    case "menu_cr_mobile":
        return (
            <div className="header-svg">
                <MenuCRMark {...props} />
            </div>
        );
    case "menu_cr_logo":
        return (
            <div className="header-svg">
                <MenuCRLogo {...props} />
            </div>
        );
    case "menu_burger":
        return <MenuBurger {...props} />;
    case "menu_search":
        return (
            <div className="header-svg">
                <MenuSearch {...props} />
            </div>
        );
    case "menu_queue":
        return (
            <div className="header-svg">
                <MenuQueue {...props} />
            </div>
        );
    case "menu_chevron":
        return <MenuChevron {...props} />;
    case "menu_user_settings":
        return (
            <div className="header-svg">
                <MenuUserSettings {...props} />
            </div>
        );
    default:
    }
};
