import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withPrefix } from "gatsby";

import Link from "@components/link";
import MenuDropdown from "@components/dropdown/MenuDropdown";
import { MenuConsumer } from "@contexts/MenuContext";
import config from "@data/config";
import S from "./Offcanvas.styled";

const ListItem = ({
    title, url, onLinkClick, activeLink,
}) => (
    <li className="menu-item">
        <Link
            to={url}
            className={`menu-item-title${activeLink ? " active" : ""}`}
            onClick={onLinkClick}
        >
            {title}
        </Link>
    </li>
);

const Offcanvas = () => {
    const { browse, genres, community } = config.menulinks;
    const { crgames } = config;
    const [activeCommLinkId, setActiveCommLinkId] = useState(null);

    useEffect(() => {
        const matchPath = community.filter(
            ({ url }) => withPrefix(url) === withPrefix("/index.html"),
        );

        if (matchPath.length > 0) {
            setActiveCommLinkId(matchPath[0].id);
        }
    }, [activeCommLinkId]);

    return (
        <MenuConsumer>
            {({ isOpen, toggle }) => {
                const onLinkClick = () => {
                    toggle();

                    const bodyClass = document.body.classList;
                    bodyClass.contains("menu-open") && bodyClass.remove("menu-open");
                };

                return (
                    <S.Offcanvas menuIsOpen={isOpen}>
                        <nav>
                            <div className="section">
                                <span className="title">Browse</span>
                                <ul className="menu">
                                    {browse.map((props) => (
                                        <ListItem
                                            key={props.title}
                                            onLinkClick={onLinkClick}
                                            {...props}
                                        />
                                    ))}
                                    <MenuDropdown
                                        title="Genres"
                                        data={genres}
                                        dataSrc="config"
                                        onLinkClick={onLinkClick}
                                    />
                                </ul>
                            </div>
                            <div className="section">
                                <ul className="menu">
                                    {community.map((props) => (
                                        <Fragment key={props.title}>
                                            <ListItem
                                                onLinkClick={onLinkClick}
                                                {...props}
                                                activeLink={
                                                    props.id === activeCommLinkId
                                                }
                                            />
                                            {props.id === "3" && (
                                                <MenuDropdown
                                                    title="Games"
                                                    data={crgames}
                                                    dataSrc="config"
                                                    onLinkClick={onLinkClick}
                                                />
                                            )}
                                        </Fragment>
                                    ))}
                                </ul>
                            </div>
                        </nav>
                    </S.Offcanvas>
                );
            }}
        </MenuConsumer>
    );
};

ListItem.propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    onLinkClick: PropTypes.func,
    activeLink: PropTypes.bool,
};

ListItem.defaultProps = {
    onLinkClick: () => {},
    activeLink: false,
};

export default Offcanvas;
