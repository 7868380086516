import React from "react";
import styled from "@emotion/styled";
import tw from "tailwind.macro";

const SVG = styled.svg`
  path {
    ${tw`text-cr-orange fill-current`};
  }
`;
export default ({
    width = "24",
    height = "24",
    className = "",
    viewBox = "0 0 24 24",
}) => (
    <SVG
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox={viewBox}
        className={className}
    >
        <path
            fillRule="evenodd"
            d="M19.766 14.484a3.87 3.87 0 0 1-1.313-7.51A8.5 8.5 0 0 0 6 14.5a8.5 8.5 0 0 0 8.5 8.5 8.5 8.5 0 0 0 8.357-10.058 3.864 3.864 0 0 1-3.091 1.542M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12c.274 0 .546-.01.815-.028C7.29 23.64 2.91 19.055 2.91 13.445c0-5.825 4.723-10.547 10.548-10.547 5.577 0 10.142 4.328 10.521 9.808.013-.234.021-.469.021-.706 0-6.627-5.373-12-12-12"
        />
    </SVG>
);
