import React from "react";
import { Global } from "@emotion/core";
import globalStyles from "./styles/globalStyles";
import S from "./Layout.styled";
import Header from "./header/Header";
import "@styles/font-face.css";

const Layout = ({ children }) => (
    <>
        <Global styles={globalStyles} />
        <S.Layout>
            <Header />
            <main>{children}</main>
        </S.Layout>
    </>
);

export default Layout;
