import crmenu from "./crmenu";
import crgames from "./crgames";
import segment from "./segment";

export default {
    ...crmenu,
    ...crgames,
    ...segment,
    crapi: {
        deviceType: "com.crunchyroll.static",
        deviceId: "whatvalueshouldbeforweb",
        accessToken: "giKq5eY27ny3cqz",
    }
};
