import React from "react";

import SVG from "@components/svg";
import Link from "@components/link";
import S from "./UserActions.styled";

const UserActions = () => (
    <S.UserActions>
        <li className="item-queue">
            <Link to="https://www.crunchyroll.com/home/queue">
                <SVG icon="menu_queue" />
            </Link>
        </li>
        <li>
            <Link to="https://www.crunchyroll.com/search">
                <SVG icon="menu_search" />
            </Link>
        </li>
        <li>
            <Link to="https://www.crunchyroll.com/acct">
                <SVG icon="menu_user_settings" />
            </Link>
        </li>
    </S.UserActions>
);

export default UserActions;
