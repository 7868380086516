import React from "react";
import styled from "@emotion/styled";
import tw from "tailwind.macro";

const SVG = styled.svg`
  .fill {
    ${tw`fill-current text-white`};
    transition: color 0.2s ease-in-out;
  }

  &:hover {
    .fill {
      ${tw`text-teal`};
    }
  }
`;
export default ({
    width = "100%",
    className = "",
    viewBox = "0 0 240 240",
}) => (
    <SVG
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={width}
        viewBox={viewBox}
        className={className}
    >
        <path
            className="fill"
            d="M120,0A120,120,0,1,0,240,120,120,120,0,0,0,120,0Zm51.64,93.52c.08,1.16.08,2.31.08,3.47,0,35.22-26.81,75.8-75.81,75.8a75.29,75.29,0,0,1-40.91-12,54.49,54.49,0,0,0,6.43.33,53.38,53.38,0,0,0,33.08-11.38A26.69,26.69,0,0,1,69.6,131.3a33.25,33.25,0,0,0,5,.41,28.47,28.47,0,0,0,7-.9,26.66,26.66,0,0,1-21.36-26.15v-.33a26.9,26.9,0,0,0,12,3.38,26.69,26.69,0,0,1-8.25-35.64A75.76,75.76,0,0,0,119,100a30.32,30.32,0,0,1-.66-6.11,26.67,26.67,0,0,1,46.11-18.23,52.36,52.36,0,0,0,16.91-6.43,26.61,26.61,0,0,1-11.71,14.68A53.53,53.53,0,0,0,185,79.75,57.28,57.28,0,0,1,171.64,93.52Z"
        />
    </SVG>
);
