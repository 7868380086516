import React, { useState, useEffect } from "react";
import Input from "./Input";
import { validateRedeemCode } from "../utils/validation";
import { submitCode } from "../api";
import errors from "../data/errors.json";

function RedeemForm() {
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState(null);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const initialCode = urlParams.get("code") || "";
        setCode(initialCode);
    }, []);

    async function handleSubmit(e) {
        e.preventDefault();

        setError("");
        try {
            validateRedeemCode(code);
            setLoading(true);
            const { statusCode, code: blackhawkCode } = await submitCode(
                code.toUpperCase()
            );
            if (statusCode === 200) {
                window.location.href = `https://www.crunchyroll.com/redeem?code=${blackhawkCode}`;
            } else {
                setError(errors.genericError.message);
            }
        } catch (err) {
            if (err.type) {
                setError(err.message);
            } else {
                setError(errors.genericError.message);
            }
        } finally {
            setLoading(false);
        }
    }

    function handleChange(e) {
        setCode(e.target.value);
    }

    return (
        <form
            onSubmit={handleSubmit}
            autoComplete="off"
            className="flex flex-col items-center md:block"
        >
            <Input
                name="code"
                error={error}
                className="w-full md:w-64"
                handleChange={handleChange}
                value={code}
                placeholder="Ingresa tu código aquí"
                disabled={code === null}
            />
            <div className="my-4 xl:my-6">
                <a
                    href="https://help.crunchyroll.com/hc/es/articles/360049533012-Tarjetas-prepago-México"
                    className="inline-block text-xs text-white opacity-50 xl:text-base hover:text-brand-blue-aqua"
                >
                    ¿Necesitas ayuda?
                </a>
            </div>
            <button
                type="submit"
                className="btn btn-primary xl:text-2xl"
                disabled={loading}
            >
                Canjear
            </button>
        </form>
    );
}

export default RedeemForm;
