import React from "react";
import PropTypes from "prop-types";

import S from "./TabNavigation.styled";

const TabNavigation = ({ className, onEnterPress, ...rest }) => {
    const onKeyDown = ({ keyCode }) => {
        if (keyCode === 13) {
            onEnterPress();
        }
    };

    return (
        <S.TabNavigation
            tabIndex="0"
            onKeyDown={onKeyDown}
            onMouseDown={(e) => e.preventDefault()}
            className={className}
        >
            {rest.children}
        </S.TabNavigation>
    );
};

TabNavigation.propTypes = {
    className: PropTypes.string,
    onEnterPress: PropTypes.func,
};

TabNavigation.defaultProps = {
    className: "",
    onEnterPress: () => {},
};

export default TabNavigation;
