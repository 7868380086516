import React from "react";
import PropTypes from "prop-types";

export default function BenefitsCard({
    title, description, icon, className = "",
}) {
    return (
        <div className={`benefits-card flex py-1 md:py-4 lg:py-8 px-3 md:px-1 lg:px-4 items-center h-24 md:h-16 bg-white box-content ${className}`}>
            <div className="w-3/12">
                <img src={icon} alt={`${title} icon`} className="transform scale-75 md:scale-90" />
            </div>
            <div className="w-9/12 pl-2 xl:pl-0">
                <h3 className="text-lg md:text-base lg:text-xl xl:text-3/2xl font-semibold text-brand-blue-700 uppercase">{title}</h3>
                <p className="text-sm md:text-xs lg:text-sm xl:text-base">{description}</p>
            </div>
        </div>
    );
}

BenefitsCard.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    className: PropTypes.string,
};

BenefitsCard.defaultProps = {
    className: "",
};
