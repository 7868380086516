import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

export default function SeriesSection() {
    const images = useStaticQuery(graphql`
        query {
            allSeriesJson {
                edges {
                    node {
                        name
                        url
                        image {
                            childImageSharp {
                                fluid(maxHeight: 500) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    return (
        <section className="series-section px-16 pt-16">
            <h2
                className="text-white section-heading series-heading pb-8"
                back-text="SERIES"
            >
                Series
            </h2>
            <div className="grid grid-cols-5 gap-2 pb-20 max-w-1366 m-auto">
                {images.allSeriesJson.edges.map(({ node: { image, name, url } }, i) => (
                    (
                        // eslint-disable-next-line react/no-array-index-key
                        <a href={url} className="block col-span-5 md:col-span-1" key={i}>
                            <Img
                                fluid={image.childImageSharp.fluid}
                                alt={name}
                            />
                        </a>
                    )
                ))}
            </div>
        </section>
    );
}
