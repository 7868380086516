import React from "react";
import styled from "@emotion/styled";
import tw from "tailwind.macro";

const SVG = styled.svg`
  .store {
    ${tw`fill-current`};
    color: #f8b717;
  }

  .crunchyroll {
    ${tw`fill-current`};
    color: #f47522;
  }

  .pipe {
    ${tw`stroke-current`};
    color: #f8b717;
    fill: none;
  }
`;
export default ({
    width = "100%",
    className = "",
    viewBox = "0 0 619.69 68.91",
}) => (
    <SVG
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={width}
        viewBox={viewBox}
        className={className}
    >
        <path
            className="store"
            d="M507.14 317.29v-.75l4.86-5.62h.7c2.59 3 7.32 5.43 11.36 5.43s6.56-1.64 6.56-4c0-3.47-4-3.91-9-5.49-5.62-1.77-12.5-4.93-12.5-12.56 0-7.13 6.31-12.12 15.46-12.12 6.69 0 12.44 2.65 15.4 6.19v.63l-4.86 5.55h-.69a13.89 13.89 0 0 0-9.72-4.48c-3.28 0-5.55 1.39-5.55 3.66 0 2.72 3.09 3.79 6.43 4.93 7 2.21 15.15 4.35 15.15 13.57 0 8-7.26 12.43-16.09 12.43-7.34.02-13.84-2.88-17.51-7.37zM567.74 323.92h-9.91V291H545v-8.1h35.66v8.1h-12.92zM582.89 303.41c0-12.18 9.72-21.27 22.53-21.27s22.47 9.09 22.47 21.27-9.72 21.27-22.47 21.27-22.53-9.09-22.53-21.27zm35 0a12.44 12.44 0 1 0-12.43 12.37 12.09 12.09 0 0 0 12.39-12.37zM668.84 323.16l-.44.76h-9.78l-8.27-15.4h-5.75v15.4h-9.84v-41h17.67c10.35 0 15.84 5 15.84 13.06 0 5.56-3.15 9.41-8.08 11.3zm-24.24-21.39h7.89c3.35 0 5.75-2 5.75-5.3s-2.4-5.49-5.75-5.49h-7.89zM685.65 291v8.52h15.52v7.7h-15.52v8.64h20.19v8.08h-30v-41h29.92V291z"
            transform="translate(-86.16 -270.43)"
        />
        <path
            className="crunchyroll"
            d="M120.61 270.43a34.46 34.46 0 0 0 0 68.91c.79 0 1.57 0 2.34-.08A30.29 30.29 0 1 1 155 306.91c0-.67.06-1.35.06-2a34.45 34.45 0 0 0-34.45-34.48zm21 40.31a11 11 0 0 1-3.74-21.4 24.21 24.21 0 1 0 13 21.44 25 25 0 0 0-.4-4.44 11 11 0 0 1-8.91 4.4zM170 309.19c0 10 5.06 15.48 14.22 15.48a23.6 23.6 0 0 0 8.69-1.68v-6a19.64 19.64 0 0 1-7.48 1.51c-5.06 0-8.19-1.57-8.19-9.28s3.13-9.28 8.19-9.28a19.82 19.82 0 0 1 7.48 1.44v-6a23.39 23.39 0 0 0-8.69-1.69c-9.22-.05-14.22 5.49-14.22 15.5zm37.17-7.53c0-1.77 4.39-1.72 8.41-1.78v-6.24s-15.61-.63-15.61 3.85v26.58h7.2zm33.27 9.88c0 4.82-1.76 6.46-6.15 6.46s-6.09-1.64-6.09-6.46v-17.42h-7.09v16.45c0 8.86 2.87 14.1 13.18 14.1s13.24-5.24 13.24-14.1v-16.45h-7.13zm67.28 5.46a19.56 19.56 0 0 1-7.48 1.51c-5.06 0-8.2-1.57-8.2-9.29s3.14-9.29 8.2-9.29a19.74 19.74 0 0 1 7.48 1.45v-6a23.31 23.31 0 0 0-8.69-1.69c-9.16 0-14.24 5.55-14.24 15.56s5.08 15.51 14.24 15.51a23.32 23.32 0 0 0 8.69-1.7zm19.68-23.34a16.25 16.25 0 0 0-6 1v-12h-7.2v41.36h7.2V300.9a11.78 11.78 0 0 1 4.79-.9c6.73 0 7.33 2.82 7.33 5.46v18.61h7.14v-18c0-7.81-4.92-12.43-13.26-12.43zM359.08 315l-6.72-20.89h-7.62l10.74 28.39c-.38 1.06 0 0-5.47 13.39h7.53l4.36-11.11 11.4-30.67h-7.62zm19.2-17.52v26.58h7.21v-22.42c0-1.75 4.42-1.7 8.44-1.76v-6.24s-15.65-.64-15.65 3.85zm32.89-3.79c-9.17 0-14.23 5.48-14.23 15.49s5.06 15.48 14.23 15.48 14.21-5.48 14.21-15.48-5.06-15.48-14.21-15.48zm0 25c-4.29 0-7-1.56-7-9.51s2.71-9.52 7-9.52 7 1.56 7 9.52-2.73 9.52-7 9.52zm20.39 5.37h7.21v-41.35h-7.21zm15.61 0h7.2v-41.35h-7.2zm-179.4-30.45c-7.46 0-13.62.67-13.62 5.65v24.78h7v-22.48c0-1.27 3.64-1.53 5.63-1.53 6.4 0 6.28 2.78 6.28 5.42v18.6h7v-18.76c.04-8.29-4.13-11.67-12.29-11.67z"
            transform="translate(-86.16 -270.43)"
        />
        <path
            className="pipe"
            strokeMiterlimit="10"
            strokeWidth="8.06"
            d="M395.97 12.1v41.71"
        />
    </SVG>
);
