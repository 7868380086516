import styled from "@emotion/styled";
import tw from "tailwind.macro";

import media from "@styles/media";

const S = {};

S.MenuItem = styled.li`
  .react-slidedown {
    ${tw`h-0`};
    transition: none 0.15s cubic-bezier(0.02, 0.01, 0.47, 1);

    &.transitioning {
      ${tw`overflow-y-hidden`};
    }

    &.closed {
      ${tw`hidden`};
    }
  }
`;

S.MenuItemTitle = styled.div`
  ${tw`relative`};

  .menu-icon {
    ${tw`absolute`};
    top: 50%;
    left: calc(100vw - 13%);
    transform: translateY(-50%);
    line-height: 0;

    ${media.menuSm`
      left: 15.75rem;
    `};
  }

  .menu-icon-chevron {
    transform: ${({ isOpen }) => (isOpen ? "rotate(-90deg)" : "rotate(90deg)")};
    transition: transform 0.1s cubic-bezier(0.5, 0, 0.25, 1);

    path {
      ${tw`text-silver-chalice`};
    }
  }
`;

S.Submenu = styled.ul`
  ${tw`list-none pl-0`};

  .submenu-item-title {
    ${tw`block text-sm font-medium text-silver hover:bg-trout`};
    line-height: 1.29;
    padding: 0.875rem 1.5rem 0.875rem 2.375rem;
    background-color: rgba(74, 78, 88, 0.5);

    &.active {
      ${tw`text-white border-0 border-l-2 border-solid border-scooter`};
      padding-left: 2.25rem;
      background-image: linear-gradient(
        to left,
        rgba(74, 78, 88, 0.5),
        rgba(74, 78, 88, 1)
      );
    }
  }
`;

export default S;
