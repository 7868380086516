import { css } from "@emotion/core";

import config from "@/tailwind.config";

const {
    theme: {
        extend: { screens },
    },
} = config;

export default Object.keys(screens).reduce((acc, label) => {
    acc[label] = (...args) => css`
    @media (min-width: ${screens[label]}) {
      ${css(...args)};
    }
  `;
    return acc;
}, {});
