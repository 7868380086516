import React from "react";

import S from "./Header.styled";

import NavButton from "../nav/button/NavButton";
import Offcanvas from "../nav/offcanvas/Offcanvas";
import Overlay from "../nav/overlay/Overlay";
import MenuLogo from "../nav/logo/MenuLogo";
import UserActions from "../nav/user/UserActions";

const Header = () => (
    <S.Header>
        <Overlay />
        <div id="header" className="header">
            <div className="content">
                <div className="menu-left">
                    <NavButton />
                </div>
                <Offcanvas />
                <MenuLogo />
                <div className="menu-right">
                    <UserActions />
                </div>
            </div>
        </div>
        <div className="header-placeholder" />
    </S.Header>
);

export default Header;
