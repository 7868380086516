import React from "react";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";
import BenefitsCard from "../BenefitsCard";
import SimulcastIcon from "../../assets/images/Group-75.svg";
import NoAdsIcon from "../../assets/images/Group-77.svg";
import OfflineIcon from "../../assets/images/Group-78.svg";
import FullHdIcon from "../../assets/images/Group-84.svg";
import Circle from "../../assets/images/Ellipse-5.svg";
import HalfCircle from "../../assets/images/Subtraction-2.svg";

export default function BenefitsSection() {
    const himeImage = useStaticQuery(graphql`
        query{
            file(name: {eq: "chibi-hime-laptop"}){
                childImageSharp{
                    fluid(maxWidth: 424, maxHeight: 410){
                        ...GatsbyImageSharpFluid_noBase64
                    }
                }
            }
        }
    `);

    const cards = [
        {
            title: "Simulcast",
            description: "¡Disfruta las series 1 hora después de su estreno en Japón!",
            icon: SimulcastIcon,
        },
        {
            title: "Sin anuncios",
            description: "Disfruta de tus series sin interrupciones publicitarias.",
            icon: NoAdsIcon,
        },
        {
            title: "Visionado offline",
            description: "Llévate tus series a donde vayas, ¡sin necesidad de conexión a Internet!",
            icon: OfflineIcon,
        },
        {
            title: "Full HD",
            description: "La mejor calidad de imagen, alta definición.",
            icon: FullHdIcon,
        },
    ];

    return (
        <section className="pt-16 md:pt-24 relative benefits-section px-4 md:px-0">
            <img src={Circle} className="absolute z-0 circle" alt="" />
            <img src={HalfCircle} className="absolute z-0 half-circle" alt="" />
            <h2
                className="text-brand-blue-700 section-heading benefits-title"
                back-text="Beneficios"
            >
                Beneficios
            </h2>
            <div className="flex flex-wrap justify-center w-full">
                {cards.slice(0, 2).map((card, i) => (
                    <BenefitsCard
                        className={`w-full md:w-5/12 lg:w-4/12 mt-4 md:mt-8 benefits-card ${i === 0 ? "md:mr-6 lg:mr-16 xl:mr-24" : "md:ml-6 lg:ml-16 ml:ml-24"}`}
                        title={card.title}
                        description={card.description}
                        icon={card.icon}
                        key={card.title}
                    />
                ))}
            </div>
            <div className="flex flex-wrap justify-center w-full">
                {cards.slice(2, 4).map((card, i) => (
                    <BenefitsCard
                        className={`w-full md:w-5/12 lg:w-4/12 mt-4 md:mt-12 benefits-card ${i === 0 ? "md:mr-12 lg:mr-32 xl:mr-40" : "md:ml-12 lg:ml-32 xl:ml-40"}`}
                        title={card.title}
                        description={card.description}
                        icon={card.icon}
                        key={card.title}
                    />
                ))}
            </div>
            <Img fluid={himeImage.file.childImageSharp.fluid} className="chibi-hime" alt="chibi hime" imgStyle={{ objectFit: "contain" }} />
        </section>
    );
}
