module.exports = {
    tailwind: {
        styled: "styled-components",
    },
    theme: {
        container: {
            center: true,
            padding: "2rem",
        },
        extend: {
            width: "100%",
            colors: {
                primary: "#EF4323",
                teal: "#2cbdbb",
                "cr-orange": "#f47521",
                "grey-150": "#dae1e7",
                "grey-750": "#3d4852",
                scooter: "#2abdbb",
                woodsmoke: "#141519",
                shark: "#23252b",
                trout: "#4a4e58",
                "scarpa-flow": "#59595b",
                "silver-chalice": "#a0a0a0",
                silver: "#dadada",
                alabaster: "#f8f8f8",
                "brand-blue": {
                    aqua: "#2ABDBB",
                    300: "#22434D",
                    500: "#213944",
                    700: "#213E48",
                    900: "#192E38",

                },
                primary: {
                    500: "#F47521",
                    600: "#F36F21",
                },
                "brand-red": {
                    500: "#EF4423",
                    600: "#EF4323",
                },
                "brand-yellow": {
                    500: "#F8B717",
                },
                "brand-black": {
                    500: "#23252B",
                },
                "brand-gray": {
                    100: "#00000029",
                    300: "#CCCCCC",
                    500: "#707070",
                    700: "#59595B",
                },
            },
            screens: {
                xs: "320px",
                sm: "540px",
                md: "720px",
                lg: "960px",
                xl: "1336px",
                menuSm: "35.5rem", // 568px
            },
            maxWidth: {
                1366: "1336px",
            },
            fontFamily: {
                header: ["Nutmeg", "sans-serif"],
                body: ["Lato", "sans-serif"],
            },
            // padding: '1rem',
            fontSize: {
                "3/2xl": "1.375rem",
                xxs: "0.6rem",
            },
        },
    },
    variants: {},
    plugins: [],
};
