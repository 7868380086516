import styled from "@emotion/styled";
import tw from "tailwind.macro";

const S = {};

S.TabNavigation = styled.div`
  ${tw`focus:outline-none active:shadow-none`};

  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(160, 160, 160, 0.75) inset;
  }
`;

export default S;
