import React from "react";

import SVG from "@components/svg";
import TabNavigation from "@components/layout/nav/tab/TabNavigation";
import { MenuConsumer } from "@contexts/MenuContext";
import S from "./NavButton.styled";

const NavButton = () => (
    <MenuConsumer>
        {({ isOpen, toggle }) => {
            const toggleMenu = () => {
                toggle();
                document.body.classList.toggle("menu-open");
            };

            return (
                <S.NavButton onClick={toggleMenu} menuIsOpen={isOpen}>
                    <TabNavigation className="menu-tab" onEnterPress={toggleMenu} />
                    <SVG icon="menu_burger" />
                    <span className="menu-title">Menu</span>
                </S.NavButton>
            );
        }}
    </MenuConsumer>
);

export default NavButton;
