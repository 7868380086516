import React from "react";
import styled from "@emotion/styled";
import tw from "tailwind.macro";

const SVG = styled.svg`
  path {
    ${tw`fill-current text-cr-orange`};
  }
`;

export default ({
    width = "100%",
    className = "",
    viewBox = "0 0 460.41 83.39",
}) => (
    <SVG
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={width}
        viewBox={viewBox}
        className={className}
    >
        <path d="M120.08 58.22a23.88 23.88 0 0 0 9-1.81v7.29a28.63 28.63 0 0 1-10.51 2c-11.09 0-17.21-6.65-17.2-18.76s6.15-18.81 17.24-18.8a28.58 28.58 0 0 1 10.5 2v7.29a23.87 23.87 0 0 0-9-1.75c-6.13 0-9.92 1.81-9.93 11.22s3.82 11.31 9.9 11.32zM156.59 28.21v7.55c-4.86.07-10.17 0-10.17 2.15v27.11h-8.72V32.86c0-5.43 18.89-4.65 18.89-4.65zM195.26 28.83v19.9c0 10.73-3.6 17.07-16 17.05s-15.95-6.36-15.94-17.08V28.8h8.58v21.07c0 5.83 2.12 7.83 7.37 7.83s7.44-2 7.45-7.81V28.81zM259.06 58.39a23.71 23.71 0 0 0 9.06-1.82v7.3a28.57 28.57 0 0 1-10.52 2c-11.08 0-17.22-6.67-17.21-18.79s6.16-18.82 17.25-18.81a28.41 28.41 0 0 1 10.52 2.06v7.29a24.09 24.09 0 0 0-9.06-1.76c-6.12 0-9.92 1.82-9.93 11.23s3.77 11.29 9.89 11.3zM308 43.39v21.8h-8.64V42.67c0-3.2-.72-6.61-8.85-6.62a14.33 14.33 0 0 0-5.81 1.08v28H276l.05-50h8.71v14.44a19.69 19.69 0 0 1 7.24-1.22c10.05.01 16 5.65 16 15.04zM319.27 79.51c6.63-16.18 6.18-14.92 6.63-16.2L313 29h9.21l8.12 25.28 8-25.27h9.22l-13.83 37.1-5.3 13.45zM372.48 28.43V36c-4.86.07-10.22 0-10.22 2.12v27.14h-8.76V33.1c.04-5.47 18.98-4.67 18.98-4.67zM410.52 47.28c0 12.1-6.15 18.73-17.22 18.72s-17.21-6.65-17.2-18.76 6.14-18.73 17.23-18.72 17.2 6.65 17.19 18.76zm-8.75 0c0-9.63-3.27-11.52-8.44-11.53s-8.47 1.89-8.48 11.51 3.27 11.52 8.45 11.53 8.46-1.9 8.47-11.52zM418 65.3v-50h8.72l-.05 50.05zM436.87 65.32l.05-50h8.71l-.05 50zM234.71 42.39v22.7h-8.46V42.58c0-3.2.15-6.56-7.59-6.57-2.42 0-6.82.31-6.82 1.85v27.22h-8.53v-30c0-6 7.47-6.83 16.49-6.82 9.88 0 14.92 4.04 14.91 14.13zM10.11 46.69a36.65 36.65 0 0 1 73.21-2.5c.05-.81.07-1.63.07-2.45a41.7 41.7 0 1 0-41.74 41.65c1 0 1.9 0 2.84-.09a36.64 36.64 0 0 1-34.38-36.61z" />
        <path d="M67 48.81a13.35 13.35 0 0 1-4.5-25.9 29.31 29.31 0 1 0 15.65 26 28.09 28.09 0 0 0-.49-5.37A13.3 13.3 0 0 1 67 48.81zM450.42 16v-.67h4.23V16h-1.76v4.52h-.73V16zM455.42 20.49v-5.2h.79l1.7 2.25 1.72-2.24h.78v5.19h-.73V16.4l-1.77 2.32-1.76-2.33v4.1z" />
    </SVG>
);
